import * as yup from "yup";
import { forcedRegistered, forcedStatePrincipal } from "../PurchaseFormHelpers";
import { sitePhrasing } from "../../../../Language/languageHandler";

export const FloridaAnnualReportValidationSchema = yup.object().shape({
  feiEin: yup.string().when("feiEinStatus", {
    is: "listed",
    then: yup
      .string()
      .required("Please enter a valid FEI/EIN (9 digits)")
      .length(10, "Must be 9 digits")
      .matches(/[0-9-]{10}/g, "Must be only digits"),
  }),
  feiEinStatus: yup.string().required("Required"),
  isThisYourInformation: yup.string().required("Required"),
  informationStillAccurate: yup.string().required("Required"),

  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedStatePrincipal[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedStatePrincipal[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    mailingAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    registeredAgent: yup.object().shape({
      agentChange: yup.string().required("Required"),
      newAgent: yup
        .string()
        .when("agentChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      agentAddressChange: yup.string().required("Required"),
      newAddress: yup.object().when("agentAddressChange", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    authorizedPerson: yup.object().shape({
      authorizedPersonChange: yup.string().required("Required"),
      removingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      removedAgents: yup
        .array()
        .when(["authorizedPersonChange", "removingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      addingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      addedAgents: yup
        .array()
        .when(["authorizedPersonChange", "addingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      changingAddress: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      changedAddress: yup.object().when("changingAddress", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedStatePrincipal[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedStatePrincipal[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
  }),

  noPublicRecordExemption: yup
    .bool()
    .required()
    .oneOf([true], "Must be checked"),
  tellingTruth: yup.bool().required().oneOf([true], "Must be checked"),
  iAmAuthorized: yup.bool().required().oneOf([true], "Must be checked"),
  annualAgree: yup.bool().required().oneOf([true], "Must be checked"),

  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
});

export const IndianaAnnualReportValidationSchema = yup.object().shape({
  entityStructure: yup.string().required("Required"),
  shareholders: yup.array().when("entityStructure", {
    is: "Professional Corporation",
    then: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup.string().required("Required"),
          lastName: yup.string().required("Required"),
          profession: yup.string().required("Required"),
          indianaProfessionalLicenseNumber: yup.string().required("Required"),
          country: yup.string().required("Required"),
          streetAddress1: yup.string().required("Required"),
          streetAddress2: yup.string(),
          city: yup.string().required("Required"),
          state: yup.string().required("Required"),
        })
      )
      .min(1, "At least one shareholder is required"),
  }),
  feiEin: yup.string().when("feiEinStatus", {
    is: "listed",
    then: yup
      .string()
      .required("Please enter a valid FEI/EIN (9 digits)")
      .length(10, "Must be 9 digits")
      .matches(/[0-9-]{10}/g, "Must be only digits"),
  }),
  feiEinStatus: yup.string().required("Required"),

  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedStatePrincipal[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedStatePrincipal[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),

    emailAddress: yup.object().shape({
      emailAddressChange: yup.string().required("Required"),
      newEmailAddress: yup
        .string()
        .when("emailAddressChange", {
          is: "yes",
          then: yup
            .string()
            .email("Please enter a valid email")
            .required("Required"),
        }),
    }),

    registeredAgent: yup.object().shape({
      agentChange: yup.string().required("Required"),
      agentChangeType: yup
        .string()
        .when("agentChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      agentType: yup
        .string()
        .when("agentChangeType", {
          is: "nameAddress",
          then: yup.string().required("Required"),
        }),

      firstName: yup
        .string()
        .when("agentType", {
          is: "individual",
          then: yup.string().required("Required"),
        }),
      lastName: yup
        .string()
        .when("agentType", {
          is: "individual",
          then: yup.string().required("Required"),
        }),
      email: yup
        .string()
        .when("agentType", {
          is: "individual",
          then: yup
            .string()
            .email("Please enter a valid email")
            .required("Required"),
        }),
      businessName: yup
        .string()
        .when("agentType", {
          is: "business",
          then: yup.string().required("Required"),
        }),

      newAddress: yup.object().when(["agentChangeType", "agentType"], {
        is: (
          agentChange: string,
          agentChangeType: string,
          agentType: string
        ) => {
          return (
            agentChange === "yes" &&
            (agentChangeType === "address" || agentType === "individual")
          );
        },
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),

    authorizedPerson: yup.object().shape({
      authorizedPersonChange: yup.string().required("Required"),
      removingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      removedAgents: yup
        .array()
        .when(["authorizedPersonChange", "removingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      addingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      addedAgents: yup
        .array()
        .when(["authorizedPersonChange", "addingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
    }),
  }),

  annualAgree: yup.string().required("Required"),
  tellingTruth: yup.string().required("Required"),
  iAmAuthorized: yup.string().required("Required"),

  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
});

export const MississippiAnnualReportValidationSchema = yup.object().shape({
  feiEin: yup.string().when("useSSN", {
    is: false,
    then: yup
      .string()
      .required("Please enter a valid FEI/EIN (9 digits)")
      .length(10, "Must be 9 digits")
      .matches(/[0-9-]{10}/g, "Must be only digits"),
  }),
  useSSN: yup.bool(),

  nonProfitMembers: yup.string().when("typeOfEntity", {
    is: "nonProfit",
    then: yup.string().required("Required"),
  }),
  isNonProfit501c3: yup.string().when("typeOfEntity", {
    is: "nonProfit",
    then: yup.string().required("Required"),
  }),
  purposeOfNonProfit: yup.string().when("isNonProfit501c3", {
    is: "yes",
    then: yup.string().required("Required"),
  }),
  nonProfitSolicitDonations: yup.string().when("typeOfEntity", {
    is: "nonProfit",
    then: yup.string().required("Required"),
  }),

  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      // changed: yup.string().required('Required'),
      newAddress: yup.object().shape({
        address: yup.string().required("Required"),
        city: yup.string().required("Required"),
        state: yup
          .string()
          .required("Required")
          .test(
            "validState",
            `This address must be in the state of ${sitePhrasing.state}`,
            (value: any) => {
              if (forcedStatePrincipal[sitePhrasing.state]) {
                if (!value) {
                  return false;
                }
                return forcedStatePrincipal[sitePhrasing.state][
                  value.toLowerCase()
                ];
              }
              return true;
            }
          ),
        zip: yup.string().required("Required"),
      }),
    }),

    NAICS: yup.object().shape({
      changeCodes: yup.string().required("Required"),
      code1: yup
        .string()
        .when("changeCodes", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
    }),

    changeOfficers: yup.string().required("Required"),

    registeredAgent: yup.object().when("changeOfficers", {
      is: "yes",
      then: yup.object().shape({
        agentChange: yup.string().required("Required"),
        agentName: yup
          .string()
          .when("agentChange", {
            is: "yes",
            then: yup.string().required("Required"),
          }),
        title: yup
          .string()
          .when("agentChange", {
            is: "yes",
            then: yup.string().required("Required"),
          }),
        newAddress: yup.object().when("agentChange", {
          is: "yes",
          then: yup.object().shape({
            address: yup.string().required("Required"),
            city: yup.string().required("Required"),
            state: yup
              .string()
              .required("Required")
              .test(
                "validState",
                `This address must be in the state of ${sitePhrasing.state}`,
                (value: any) => {
                  if (forcedRegistered[sitePhrasing.state]) {
                    if (!value) {
                      return false;
                    }
                    return forcedRegistered[sitePhrasing.state][
                      value.toLowerCase()
                    ];
                  }
                  return true;
                }
              ),
            zip: yup.string().required("Required"),
          }),
        }),
      }),
    }),

    authorizedPerson: yup.object().when("changeOfficers", {
      is: "yes",
      then: yup.object().shape({
        addingAgents: yup.string().required("Required"),
        addedAgents: yup.array().when("addingAgents", {
          is: "yes",
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
        removingAgents: yup.string().required("Required"),
        removedAgents: yup.array().when("removingAgents", {
          is: "yes",
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      }),
    }),
  }),

  tellingTruth: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  iAmAuthorized: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
  informationAccurate: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  annualAgree: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  signerAddress: yup.object().shape({
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    zip: yup.string().required("Required"),
  }),
});

export const NorthCarolinaAnnualReportValidationSchema = yup.object().shape({
  // add proper validation for NC
  veteranOwned: yup.string().required("Required"),
  disabledVeteranOwned: yup
    .string()
    .when("veteranOwned", {
      is: "yes",
      then: yup.string().required("Required"),
    }),
  netReceiptsUnder1M: yup.string().required("Required"),
  natureOfBusiness: yup.string().required("Required"),

  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          // state: yup.string().required('Required').test('validState', `This address must be in the state of ${sitePhrasing.state}`,
          //     (value: any) => {
          //         if (forcedStatePrincipal[sitePhrasing.state]) {
          //             if (!value) { return false }
          //             return forcedStatePrincipal[sitePhrasing.state][value.toLowerCase()]
          //         }
          //         return true
          //     }),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    mailingAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    registeredAgent: yup.object().shape({
      agentChange: yup.string().required("Required"),
      newAgent: yup
        .string()
        .when("agentChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      email: yup
        .string()
        .when("agentChange", {
          is: "yes",
          then: yup
            .string()
            .email("Please enter a valid email address")
            .required("Required"),
        }),

      agentAddressChange: yup.string().required("Required"),
      agentMailingAddressChange: yup
        .string()
        .when("agentAddressChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      newAddress: yup.object().when("agentMailingAddressChange", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),

      agentStreetAddressChange: yup
        .string()
        .when("agentAddressChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      newStreetAddress: yup.object().when("agentStreetAddressChange", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
          county: yup.string().required("Required"),
        }),
      }),
    }),

    authorizedPerson: yup.object().shape({
      authorizedPersonChange: yup.string().required("Required"),
      removingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      removedAgents: yup
        .array()
        .when(["authorizedPersonChange", "removingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
      addingAgents: yup
        .string()
        .when("authorizedPersonChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      addedAgents: yup
        .array()
        .when(["authorizedPersonChange", "addingAgents"], {
          is: (a: string, b: string) => {
            return a === "yes" && b === "yes";
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string().required("Required"),
                title: yup.string().required("Required"),
                address: yup.string().required("Required"),
                city: yup.string().required("Required"),
                state: yup.string().required("Required"),
                zip: yup.string().required("Required"),
              })
            )
            .min(1, "Please add an entry"),
        }),
    }),
  }),

  iAmAuthorized: yup.bool().required().oneOf([true], "Must be checked"),
  annualAgree: yup.bool().required().oneOf([true], "Must be checked"),

  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
});

export const TennesseeAnnualReportValidationSchema = yup.object().shape({
  membersWithStake: yup
    .number()
    .required("Required")
    .min(1, "Must be at least 1"),
  informationChange: yup.object().shape({
    principalAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedStatePrincipal[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedStatePrincipal[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),
    mailingAddress: yup.object().shape({
      changed: yup.string().required("Required"),
      newAddress: yup.object().when("changed", {
        is: "yes",
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup.string().required("Required"),
          zip: yup.string().required("Required"),
        }),
      }),
    }),

    registeredAgent: yup.object().shape({
      agentChange: yup.string().required("Required"),
      agentType: yup
        .string()
        .when("agentChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),

      firstName: yup
        .string()
        .when("agentType", {
          is: "individual",
          then: yup.string().required("Required"),
        }),
      lastName: yup
        .string()
        .when("agentType", {
          is: "individual",
          then: yup.string().required("Required"),
        }),
      businessName: yup
        .string()
        .when("agentType", {
          is: "organization",
          then: yup.string().required("Required"),
        }),

      newAddress: yup.object().when(["agentChange", "agentType"], {
        is: (agentChange: string, agentType: string) => {
          return (
            agentChange === "yes" &&
            (agentType === "organization" || agentType === "individual")
          );
        },
        then: yup.object().shape({
          address: yup.string().required("Required"),
          city: yup.string().required("Required"),
          state: yup
            .string()
            .required("Required")
            .test(
              "validState",
              `This address must be in the state of ${sitePhrasing.state}`,
              (value: any) => {
                if (forcedRegistered[sitePhrasing.state]) {
                  if (!value) {
                    return false;
                  }
                  return forcedRegistered[sitePhrasing.state][
                    value.toLowerCase()
                  ];
                }
                return true;
              }
            ),
          zip: yup.string().required("Required"),
        }),
      }),
    }),

    llcManager: yup.object().shape({
      llcManagerChange: yup.string().required("Required"),
      removingAgents: yup
        .string()
        .when("llcManagerChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      removedAgents: yup.array().when(["llcManagerChange", "removingAgents"], {
        is: (a: string, b: string) => {
          return a === "yes" && b === "yes";
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required("Required"),
              // title: yup.string().required('Required'),
              address: yup.string().required("Required"),
              city: yup.string().required("Required"),
              state: yup.string().required("Required"),
              zip: yup.string().required("Required"),
            })
          )
          .min(1, "Please add an entry"),
      }),
      addingAgents: yup
        .string()
        .when("llcManagerChange", {
          is: "yes",
          then: yup.string().required("Required"),
        }),
      addedAgents: yup.array().when(["llcManagerChange", "addingAgents"], {
        is: (a: string, b: string) => {
          return a === "yes" && b === "yes";
        },
        then: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required("Required"),
              // title: yup.string().required('Required'),
              address: yup.string().required("Required"),
              city: yup.string().required("Required"),
              state: yup.string().required("Required"),
              zip: yup.string().required("Required"),
            })
          )
          .min(1, "Please add an entry"),
      }),
    }),
  }),
  annualAgree: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  tellingTruth: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  iAmAuthorized: yup
    .bool()
    .oneOf([true], "You must agree to this statement to continue"),
  electronicSignature: yup.string().required("Required"),
  title: yup.string().required("Required"),
});

export const StateAnnualValidation: any = {
  Florida: FloridaAnnualReportValidationSchema,
  Indiana: IndianaAnnualReportValidationSchema,
  Mississippi: MississippiAnnualReportValidationSchema,
  "North Carolina": NorthCarolinaAnnualReportValidationSchema,
  Tennessee: TennesseeAnnualReportValidationSchema,
};
