import { sitePhrasing } from "../../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import { ArrayEntryFieldType, ArrayFieldInput } from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import SelectFieldInput from "../../../FormFields/SelectInputField/SelectInputField";
import TextAreaInputField from "../../../FormFields/TextAreaInputField/TextAreaInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import { yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered } from "../PurchaseFormHelpers";

export default function MississippiAnnualReportFields(props: {
    values: any,
    errors: any,
    touched: any,
}) {
    const {
        values,
        errors,
        touched,
    } = props;

    return (
        <>
            <h3 style={{ textAlign: 'left' }}>
                Company Principal Address*
            </h3>
            <AddressInputFieldGroup
                fieldName={'annualReport.informationChange.principalAddress.newAddress'}
                errors={errors}
                touched={touched}
            />

            <br />

            {!values.annualReport.useSSN && (
                <TextInputField
                    mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    fieldName={'annualReport.feiEin'}
                    fieldLabel={'FEI/EIN Number'}
                    errors={errors}
                    touched={touched}
                    required={!values.annualReport.useSSN}
                    placeholder={'FEI/EIN Number'}
                />
            )}

            <CheckboxInputField
                fieldName={'annualReport.useSSN'}
                fieldLabel={'OR - Check the box if you are using your SSN for tax purposes for this company.'}
                errors={errors}
                touched={touched}
            />

            <div className="field-note">
                If you have not yet obtained your EIN Federal Tax ID, you may do so now by <a href="/" target="_blank" className="link-text">clicking here</a>.
            </div>


            {values.annualReport.typeOfEntity === 'nonProfit' && (
                <>
                    <RadioInputField
                        fieldName={'annualReport.nonProfitMembers'}
                        fieldLabel={'Does this Non-Profit have members?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />

                    <RadioInputField
                        fieldName={'annualReport.isNonProfit501c3'}
                        fieldLabel={'Is this Non-Profit Corporation approved by the IRS under section 501(c)(3)?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />

                    {values.annualReport.isNonProfit501c3 === 'yes' && (
                        <TextAreaInputField
                            fieldName={'annualReport.purposeOfNonProfit'}
                            fieldLabel={'State the Non-Profit purpose listed on the 501(c)(3) application'}
                            errors={errors}
                            fieldType={'textarea'}
                            touched={touched}
                            required={true}
                            placeholder={''}
                            rows={4}
                        />
                    )}

                    <RadioInputField
                        fieldName={'annualReport.nonProfitSolicitDonations'}
                        fieldLabel={'Does this Non-Profit Corporation solicit donations?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />

                </>
            )}



            <h3>
                Do you need to change any of the following information?
            </h3>

            <RadioInputField
                fieldName={'annualReport.informationChange.NAICS.changeCodes'}
                fieldLabel={'Nature of Business (NAICS codes)?'}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />

            {values.annualReport.informationChange.NAICS.changeCodes === 'yes' && (
                <div className="naics-section">
                    (Enter up to 3 NAICS codes)
                    <div className="naics-split-box">
                        <TextInputField
                            fieldName={'annualReport.informationChange.NAICS.code1'}
                            fieldLabel={'1.'}
                            errors={errors}
                            touched={touched}
                            required={true}
                            customClass="inline-input border-input"
                            placeholder={'NAICS Code'}
                        />
                        <TextInputField
                            fieldName={'annualReport.informationChange.NAICS.code2'}
                            fieldLabel={'2.'}
                            errors={errors}
                            touched={touched}
                            required={false}
                            customClass="inline-input border-input"
                            placeholder={'NAICS Code'}
                        />
                        <TextInputField
                            fieldName={'annualReport.informationChange.NAICS.code3'}
                            fieldLabel={'3.'}
                            errors={errors}
                            touched={touched}
                            required={false}
                            customClass="inline-input border-input"
                            placeholder={'NAICS Code'}
                        />
                    </div>
                    <a href="https://www.naics.com/search/" target="_blank" className="link-text">
                        Click Here to search for NAICS codes
                    </a>
                </div>
            )}

            <CheckboxInputField
                fieldName={'annualReport.hasWrittenOperatingAgreement'}
                fieldLabel={'This LLC has a written operating agreement'}
                errors={errors}
                touched={touched}
            />

            <RadioInputField
                fieldName={'annualReport.informationChange.changeOfficers'}
                fieldLabel={'Officers and Directors?'}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />

            {values.annualReport.informationChange.changeOfficers === 'yes' && (
                <>
                    {/* <RadioInputField
                        fieldName={'annualReport.informationChange.registeredAgent.agentChange'}
                        fieldLabel={'Editing current Officers/Directors?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    /> */}

                    {values.annualReport.informationChange.registeredAgent.agentChange === 'yes' && (
                        <>
                            <h3
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                Enter information to be updated:
                            </h3>
                            <TextInputField
                                fieldName={'annualReport.informationChange.registeredAgent.agentName'}
                                fieldLabel={'Name'}
                                errors={errors}
                                touched={touched}
                                required={true}
                                placeholder={'Name'}
                            />
                            {/* ==============  MAKE THIS A SELECT FIELD ================= */}
                            <SelectFieldInput
                                fieldName={'annualReport.informationChange.registeredAgent.title'}
                                fieldLabel={'Title'}
                                errors={errors}
                                touched={touched}
                                required={true}
                                placeholder={'- Select Title -'}
                                options={titleValues}
                            />
                            <AddressInputFieldGroup
                                fieldName={'annualReport.informationChange.registeredAgent.newAddress'}
                                errors={errors}
                                touched={touched}
                                fieldMessages={{
                                    state: forcedRegistered[sitePhrasing.state] ? `Your registered agent must be located in ${sitePhrasing.state}.` : ``
                                }}
                                disableFields={{
                                    state: forcedRegistered[sitePhrasing.state] ? true : false,
                                }}
                            />
                        </>
                    )}

                    <RadioInputField
                        fieldName={'annualReport.informationChange.authorizedPerson.addingAgents'}
                        fieldLabel={`Addings New Officers/Directors?`}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.annualReport.informationChange.authorizedPerson.addingAgents === 'yes' && (
                        <ArrayFieldInput
                            fieldName={'annualReport.informationChange.authorizedPerson.addedAgents'}
                            values={values.annualReport.informationChange.authorizedPerson.addedAgents}
                            blankEntryFn={blankRegisteredAgentFn}
                            fields={registeredAgentFields}
                            touched={touched}
                            errors={errors}
                            removeText={'- Click to remove entry'}
                            addText={'+ Click to add an entry'}
                        />
                    )}

                    <RadioInputField
                        fieldName={'annualReport.informationChange.authorizedPerson.removingAgents'}
                        fieldLabel={`Removing Officers/Directors?`}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.annualReport.informationChange.authorizedPerson.removingAgents === 'yes' && (
                        <ArrayFieldInput
                            fieldName={'annualReport.informationChange.authorizedPerson.removedAgents'}
                            values={values.annualReport.informationChange.authorizedPerson.removedAgents}
                            blankEntryFn={blankRegisteredAgentFn}
                            fields={registeredAgentFields}
                            touched={touched}
                            errors={errors}
                            removeText={'- Click to remove entry'}
                            addText={'+ Click to add an entry'}
                        />
                    )}


                </>
            )}

            <CheckboxInputField
                fieldName={'annualReport.iAmAuthorized'}
                fieldLabel={`The undersigned, by typing my name in the box below, certifies that I am authorized to file this document on behalf of this entity, has examined the document and, to the best of my knowledge and belief, it is true, correct and complete as of this day ${new Date().toLocaleDateString()}`}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'annualReport.informationAccurate'}
                fieldLabel={'I hereby certify that the information indicated on this report or supplemental report is true and accurate and that my electronic signature shall have the same legal effect as if made under oath; that I am authorized to execute this report. I authorize Mississippi Business Filing Center to submit this application on my behalf with the State of Mississippi.'}
                touched={touched}
                errors={errors}
            />

            <TextInputField
                fieldName={'annualReport.electronicSignature'}
                fieldLabel={'Name of Individual Signing - Electronic Signature'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'Electronic Signature'}
                customClass={'signature-font'}
            />

            <SelectFieldInput
                fieldName={'annualReport.title'}
                fieldLabel={'Title'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'- Select Title -'}
                options={signatureTitleValues}
            />

            <CheckboxInputField
                fieldName={'annualReport.tellingTruth'}
                fieldLabel={`This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual. The individual "signing" this document affirms that the facts stated herein are true.`}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'annualReport.annualAgree'}
                fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Mississippi Business Filing Center as a "Third Party Designee", to answer any questions on my behalf or the behalf of the entity listed above about the completion of this Annual Report, and to receive and deliver to me the Annual Report for me or the entity listed above. I agree to the terms & conditions and authorize this payment to Mississippi Business Filing Center. I hereby certify that the information above is correct and that I am authorized to conduct this transaction.`}
                touched={touched}
                errors={errors}
            />

            <AddressInputFieldGroup
                fieldName={'annualReport.signerAddress'}
                errors={errors}
                touched={touched}
            />


        </>
    )
}

export const blankRegisteredAgentFn = () => ({
    name: '',
    title: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
})

const titleValues = [
    {
        label: 'Manager',
        value: 'Manager',
    },
    {
        label: 'Member',
        value: 'Member',
    },
    {
        label: 'President',
        value: 'President',
    },
    {
        label: 'Other',
        value: 'Other',
    },
    {
        label: 'Vice President',
        value: 'Vice President',
    },
    {
        label: 'Secretary',
        value: 'Secretary',
    },
    {
        label: 'Treasurer',
        value: 'Treasurer',
    },
    {
        label: 'Attorney In Fact',
        value: 'Attorney In Fact',
    },
]

export const registeredAgentFields: ArrayEntryFieldType[] = [
    {
        fieldType: 'text',
        fieldName: 'name',
        fieldLabel: 'Name',
        placeholder: 'Name',
        required: true,
    },
    {
        fieldType: 'select',
        fieldName: 'title',
        fieldLabel: 'Title',
        placeholder: '- Select Title -',
        required: true,
        options: titleValues,
    },
    {
        fieldType: 'text',
        fieldName: 'address',
        fieldLabel: 'Street',
        placeholder: 'Street',
        required: true,
    },
    {
        fieldType: 'text',
        fieldName: 'address2',
        fieldLabel: 'Apt #',
        placeholder: 'Apt #'
    },
    {
        fieldType: 'text',
        fieldName: 'city',
        fieldLabel: 'City',
        placeholder: 'City',
        required: true,
    },
    {
        fieldType: 'text',
        fieldName: 'state',
        fieldLabel: 'State',
        placeholder: 'State',
        required: true,
    },
    {
        fieldType: 'text',
        fieldName: 'zip',
        fieldLabel: 'Zip',
        placeholder: 'Zip',
        required: true,
    },
]

// const signatureTitleValues = [
//     {
//         value: 'other',
//         label: 'Other',
//     },
//     {
//         value: 'manager',
//         label: 'Manager',
//     },
//     {
//         value: 'member',
//         label: 'Member',
//     },
//     {
//         value: 'power of attorney',
//         label: 'Power of Attorney',
//     },
//     {
//         value: 'president',
//         label: 'President',
//     },
//     {
//         value: 'secretary',
//         label: 'Secretary',
//     },
//     {
//         value: 'treasurer',
//         label: 'Treasurer',
//     },
//     {
//         value: 'vice president',
//         label: 'Vice President',
//     }
// ]

const signatureTitleValues = [
    {
        value: 'Assistant Secretary',
        label: 'Assistant Secretary',
    },
    {
        value: 'Assistant Treasurer',
        label: 'Assistant Treasurer',
    },
    {
        value: 'Chief Executive Officer',
        label: 'Chief Executive Officer',
    },
    {
        value: 'Chief Financial Officer',
        label: 'Chief Financial Officer',
    },
    {
        value: 'Chairman',
        label: 'Chairman',
    },
    {
        value: 'Director',
        label: 'Director',
    },
    {
        value: 'Power of Attorney',
        label: 'Power of Attorney',
    },
    {
        value: 'President',
        label: 'President',
    },
    {
        value: 'Secretary',
        label: 'Secretary',
    },
    {
        value: 'Treasurer',
        label: 'Treasurer',
    },
    {
        value: 'Vice President',
        label: 'Vice President',
    }
]